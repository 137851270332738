// Prod form

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { countryList } from "../utils/utils";
import { getDemoFlowsByGroupLink, makeCallProd } from "../services/flowService";
import PhoneIcon from "../assets/PhoneIcon";
import {
  getProdServicesStatus,
  getProdServicesStatusFromTeleserver,
  startProdServices,
} from "../services/configService";
import RefreshIcon from "../assets/RefreshIcon";

const MakeCallForm = ({ company_id }) => {
  const [loading, setLoading] = useState();
  const [msg, setMsg] = useState("");
  const [flows, setFlows] = useState([]);

  useEffect(() => {
    const getAllFlowsByGroupId = async (group_link) => {
      const response = await getDemoFlowsByGroupLink(group_link);
      setFlows(response);
    };

    getAllFlowsByGroupId(company_id);
  }, [company_id]);

  //Dynamic form values

  const [selectedFlowId, setSelectedFlowId] = useState("");

  let selectedFlow = "";

  if (Array.isArray(flows)) {
    selectedFlow = flows?.find((flow) => flow.id === selectedFlowId);
  }

  let filteredVariablesArr =
    selectedFlow?.variables?.variables.filter((elem) => elem.value_required) ||
    [];

  let form_variable_fields =
    filteredVariablesArr?.reduce((obj, elem) => {
      return elem.type === "phone"
        ? {
            ...obj,
            [elem.name]: elem.default_value || "",
            [`countryCode_${elem.name}`]: "+91",
          }
        : {
            ...obj,
            [elem.name]: elem.default_value || "",
          };
    }, {}) || {};

  // Yup validation schema

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      Object.keys(form_variable_fields).reduce(
        (obj, elem) => {
          const other_variable = filteredVariablesArr.find(
            (item) => item.name === elem
          );
          if (!elem) {
            return { ...obj };
          } else if (other_variable?.type === "phone") {
            return {
              ...obj,
              [elem]: Yup.string()
                .matches(/^[0-9]{10}$/, `${elem} must be 10 digit`)
                .required(`${elem} is required`),
            };
          } else {
            return {
              ...obj,
              [elem]: Yup.string()
                // .matches(/^[^_]*$/, `${elem} cannot contain underscore`)
                .required(`${elem} is required`),
            };
          }
        },
        {
          name: Yup.string()
            .min(1, "Name must be atleast 1 character")
            .matches(/^[^_]*$/, "Name cannot contain underscore")
            .required("Name is required"),
          countryCode: Yup.string().required("Country Code is required"),
          phone: Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digit")
            .required("Phone number is required"),
        }
      )
    );
  }, [JSON.stringify(form_variable_fields)]);

  // Start Prod Services

  const [servicesStartTime, setServicesStartTime] = useState("");
  const [startProdServicesLoading, setStartProdServicesLoading] =
    useState(false);

  const handleStartProdServicesClick = async () => {
    setStartProdServicesLoading(true);
    await startProdServices(selectedFlowId);
    handleGetProdServicesStatus();
    localStorage.setItem(
      `${selectedFlowId}_start_time`,
      new Date().toLocaleTimeString()
    );
    setServicesStartTime(new Date().toLocaleTimeString());

    setStartProdServicesLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem(`${selectedFlowId}_start_time`)) {
      setServicesStartTime(
        localStorage.getItem(`${selectedFlowId}_start_time`)
      );
    }
  }, [selectedFlowId]);

  // prod services status
  const [prodServicesStatus, setProdServicesStatus] = useState({});
  const [prodServicesStatusLoading, setProdServicesStatusLoading] =
    useState(false);

  const handleGetProdServicesStatus = useCallback(async () => {
    setProdServicesStatusLoading(true);
    const res = await getProdServicesStatus(selectedFlowId);
    const res2 = await getProdServicesStatusFromTeleserver(selectedFlowId);
    if (res?.data?.status === "success" && res2?.status === 200)
      setProdServicesStatus({
        ...res?.data?.message,
        services_status: res2?.data?.status,
        res2_message: res2?.data?.message,
      });

    setProdServicesStatusLoading(false);
  }, [selectedFlowId]);

  useEffect(() => {
    if (selectedFlowId) {
      handleGetProdServicesStatus();
    }
  }, [selectedFlowId, handleGetProdServicesStatus]);

  return (
    <>
      <div className="my-4 text-base list-none text-black bg-white divide-y divide-gray-100 rounded-md shadow-lg md:w-[30rem] text-left p-4 border border-slate-300">
        <div className="space-y-4">
          <div>
            <label
              htmlFor="flow"
              className="block text-sm font-medium text-gray-700 break-words">
              <span className="text-red-500">*</span>Conversation Flow
            </label>
            <select
              name="flow"
              id="flow"
              onChange={(e) => {
                setSelectedFlowId(e.target.value);
              }}
              value={selectedFlowId}
              className={
                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md cursor-pointer"
              }>
              <option value="" disabled>
                Please select flow
              </option>
              {Array.isArray(flows) ? (
                flows.map((flow, index) => (
                  <option key={flow.id} value={flow.id}>
                    {flow.name}
                  </option>
                ))
              ) : (
                <span>No flows available for this url</span>
              )}
            </select>
          </div>

          <Formik
            initialValues={{
              name: "Kanik",
              countryCode: "+91",
              phone: "",
              ...form_variable_fields,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setMsg("");
              setLoading(true);

              // Calling Prod backend

              const data = Object.keys(form_variable_fields).reduce(
                (obj, elem) => {
                  if (!elem) {
                    return obj;
                  }
                  const other_variable = filteredVariablesArr.find(
                    (item) => item.name === elem
                  );
                  if (!other_variable) {
                    return obj;
                  }
                  if (other_variable.type === "phone") {
                    return {
                      ...obj,
                      other_values: {
                        ...obj.other_values,
                        [elem]: `${values[`countryCode_${elem}`]}${
                          values[elem]
                        }`,
                      },
                    };
                  } else {
                    return {
                      ...obj,
                      other_values: {
                        ...obj.other_values,
                        [elem]: values[elem],
                      },
                    };
                  }
                },
                {
                  user_name: values.name,
                  user_phone: values.countryCode + values.phone,
                  other_values: {},
                }
              );

              makeCallProd(selectedFlowId, data)
                .then((response) => {
                  setLoading(false);
                  setMsg(response.data?.message);
                })
                .catch((err) => {
                  setLoading(false);
                  setMsg("some error occurred");
                });
            }}>
            {({ errors, touched, values, handleChange, handleBlur }) => {
              return (
                <Form className="space-y-4">
                  {selectedFlowId && (
                    <div className="border border-gray-300 flex flex-col gap-2 p-2 rounded bg-gray-100">
                      <div className="flex gap-2 items-center">
                        <div className="text-nowrap border-r border-black pr-4">
                          <button
                            disabled={startProdServicesLoading}
                            onClick={handleStartProdServicesClick}
                            className="bg-gray-800 hover:bg-gray-700 disabled:bg-gray-300 text-gray-100 px-2 py-1 rounded cursor-pointer whitespace-nowrap">
                            Start Prod Services
                          </button>
                        </div>

                        <div className="flex flex-col pl-2 gap-1 w-full">
                          <div className="text-sm flex items-center gap-1 w-full">
                            <div>
                              Status:{" "}
                              {prodServicesStatusLoading ? (
                                <span>Loading...</span>
                              ) : prodServicesStatus?.schedule_status ===
                                "Not Found" ? (
                                <span>Down</span>
                              ) : prodServicesStatus?.schedule_status ===
                                  "Found" &&
                                prodServicesStatus?.services_status ? (
                                <span>Up</span>
                              ) : (
                                <span>Starting...</span>
                              )}
                            </div>

                            <div
                              disabled={prodServicesStatusLoading}
                              onClick={() => {
                                handleGetProdServicesStatus();
                              }}
                              className="ml-auto bg-gray-300 rounded hover:bg-gray-400 disabled:bg-gray-300 cursor-pointer flex items-center justify-center ver h-5 w-5">
                              {prodServicesStatusLoading ? (
                                "..."
                              ) : (
                                <RefreshIcon />
                              )}
                            </div>
                          </div>
                          {prodServicesStatusLoading ? (
                            <div className="h-[2.25rem]"></div>
                          ) : prodServicesStatus?.schedule_status ===
                            "Not Found" ? (
                            <>
                              <div className="mr-1 text-xs">
                                Services start in: NA
                              </div>
                              <div className="mr-1 text-xs">
                                Services stop in: NA
                              </div>
                            </>
                          ) : (
                            prodServicesStatus?.schedule_status === "Found" && (
                              <>
                                <div className="mr-1 text-xs">
                                  {prodServicesStatus?.services_status ? (
                                    <span>Services are up</span>
                                  ) : prodServicesStatus?.time_left_to_start_services !==
                                    "Services are up" ? (
                                    `Services start in: ${prodServicesStatus?.time_left_to_start_services} (approx)`
                                  ) : (
                                    `Taking longer than usual. Services will start in some time`
                                  )}
                                </div>
                                <div className="mr-1 text-xs">
                                  {`Services stop in: ${prodServicesStatus?.time_left_to_stop_prod}`}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                      <div className="text-sm">
                        {servicesStartTime
                          ? `After starting initially, please wait 15 minutes for services to start. Please click on the button again within 45 minutes post that, if you wish to continue using prod services.`
                          : ""}
                      </div>
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 break-words">
                      <span className="text-red-500">*</span>Name
                    </label>
                    <Field
                      name="name"
                      type="text"
                      disabled={!!!selectedFlowId}
                      className={
                        "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-gray-100 disabled:bg-gray-100 rounded-md disabled:text-slate-400 placeholder:text-slate-300" +
                        (errors.name && touched.name ? " border-red-500" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="flex w-full gap-3">
                    <div className="w-[50%]">
                      <label
                        htmlFor="countryCode"
                        className="block text-sm font-medium text-gray-700 break-words">
                        <span className="text-red-500">*</span>Phone
                      </label>
                      <Field
                        as="select"
                        name="countryCode"
                        disabled={!!!selectedFlowId}
                        className={
                          "mt-1 block w-full py-2 px-3 border border-gray-300 disabled:border-gray-100 disabled:bg-gray-100 bg-white rounded-md shadow-sm focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:text-slate-400" +
                          (errors.countryCode && touched.countryCode
                            ? " border-red-500"
                            : "")
                        }>
                        {countryList.map((country, index) => (
                          <option key={country.code} value={country.dial_code}>
                            {`${country.flag} (${country.dial_code})      ${country.code} ${country.name}`}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="countryCode"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="w-full relative top-3 flex flex-col gap-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 break-words"></label>
                      <div className="gap-3">
                        <Field
                          name="phone"
                          type="number"
                          disabled={!!!selectedFlowId}
                          className={
                            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-gray-100 disabled:bg-gray-100 rounded-md placeholder:text-slate-300" +
                            (errors.phone && touched.phone
                              ? " border-red-500"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                    </div>
                  </div>

                  {filteredVariablesArr.map((v) => {
                    if (v.type === "string") {
                      return (
                        <div key={v.name}>
                          <label
                            htmlFor={v.name}
                            className="block text-sm font-medium text-gray-700 break-words">
                            <span className="text-red-500">*</span>
                            {v.name}
                          </label>
                          <Field
                            name={v.name}
                            type="text"
                            className={
                              "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                              (errors[v.name] && touched[v.name]
                                ? " border-red-500"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name={v.name}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      );
                    } else if (v.type === "dropdown") {
                      return (
                        <div key={v.name}>
                          <label
                            htmlFor={v.name}
                            className="block text-sm font-medium text-gray-700 break-words">
                            <span className="text-red-500">*</span>
                            {v.name}
                          </label>
                          <Field
                            className={
                              "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                              (errors[v.name] && touched[v.name]
                                ? " border-red-500"
                                : "")
                            }
                            as="select"
                            name={v.name}>
                            <option value="">Select {v.name}</option>
                            {v.options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name={v.name}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      );
                    } else if (v.type === "phone") {
                      return (
                        <div key={v.name} className="flex w-full gap-3">
                          <div className="w-[50%]">
                            <label
                              htmlFor={`countryCode_${v.name}`}
                              className="block text-sm font-medium text-gray-700 break-words">
                              <span className="text-red-500">*</span>
                              {v.name}
                            </label>
                            <Field
                              as="select"
                              name={`countryCode_${v.name}`}
                              className={
                                "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" +
                                (errors[`countryCode_${v.name}`] &&
                                touched[`countryCode_${v.name}`]
                                  ? " border-red-500"
                                  : "")
                              }>
                              {countryList.map((country, index) => (
                                <option
                                  key={country.code}
                                  value={country.dial_code}>
                                  {`${country.flag} (${country.dial_code})      ${country.code} ${country.name}`}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`countryCode_${v.name}`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>

                          <div className="w-full relative top-3 flex flex-col gap-2">
                            <label
                              htmlFor={v.name}
                              className="block text-sm font-medium text-gray-700 break-words"></label>
                            <div className="gap-3">
                              <Field
                                name={v.name}
                                type="number"
                                className={
                                  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                                  (errors[v.name] && touched[v.name]
                                    ? " border-red-500"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name={v.name}
                                component="div"
                                className="text-red-500 text-sm mt-1"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    } else if (v.type === "textarea") {
                      return (
                        <div key={v.name}>
                          <label
                            htmlFor={v.name}
                            className="block text-sm font-medium text-gray-700 break-words">
                            <span className="text-red-500">*</span>
                            {v.name}
                          </label>
                          <Field
                            as="textarea"
                            name={v.name}
                            rows={4} // Set the desired number of rows
                            className={
                              "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                              (errors[v.name] && touched[v.name]
                                ? " border-red-500"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name={v.name}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      );
                    }
                  })}

                  <div className="flex flex-col items-center gap-2 justify-center z-30">
                    <button
                      type="submit"
                      disabled={loading || !!!selectedFlowId}
                      className={`border-[1px] rounded text-white px-3 py-2 font-bold text-sm flex items-center gap-2 disabled:bg-gray-100 disabled:border-gray-100 disabled:text-slate-400 ${
                        loading
                          ? "bg-gray-300 text-slate-400"
                          : "bg-sky-600 hover:bg-sky-700"
                      }`}>
                      <PhoneIcon />
                      <span>Start Call</span>
                    </button>
                    <div>{msg}</div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default MakeCallForm;

import axios from "axios";

const automation_service_url =
  process.env.REACT_APP_CALLING_CONFIGURATION_BACKEND_URL;

const prod_teleserver_url = process.env.REACT_APP_PROD_TELESERVER_URL;

export const startProdServices = async (flow_id) => {
  try {
    const response = await axios.put(
      `${automation_service_url}v1/orchestration/start/prod/${flow_id}`
    );
    return response;
  } catch (err) {
    console.error("Error occurred while starting prod services");

    throw new Error("Some error occurred while starting prod services");
  }
};

export const getProdServicesStatus = async (flow_id) => {
  try {
    const response = await axios.get(
      `${automation_service_url}v1/orchestration/status/prod/${flow_id}`
    );
    return response;
  } catch (err) {
    console.error("Error occurred while getting prod services status");

    throw new Error("Some error occurred while getting prod services status");
  }
};

export const getProdServicesStatusFromTeleserver = async (flow_id) => {
  try {
    const response = await axios.get(
      `${prod_teleserver_url}service/status/${flow_id}`
    );
    return response;
  } catch (err) {
    console.error(
      "Error occurred while getting prod services status from teleserver"
    );

    throw new Error("Some error occurred while getting prod services status");
  }
};
